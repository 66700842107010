/* mobile.css */

@media (max-width: 768px) {
    .container, .admin-container, .accessibility-container, .category-posts-container, .contact-us-container, .dashboard-container, .form-container {
        padding: 10px;
    }

    .header-section, .choose-container, .section {
        left: 0;
        width: 100%;
        margin-bottom: 20px;
    }

    .header-section .text-content h1 {
        font-size: 2em;
    }

    .header-section p, .section p {
        font-size: 1em;
    }

    .sidebar {
        width: 100%;
        position: relative;
        box-shadow: none;
    }

    .main-content {
        padding: 20px;
    }

    .features-section, .why-container {
        flex-direction: column;
        align-items: center;
    }

    .job-grid {
        grid-template-columns: 1fr;
    }

    .card, .job-card, .notification-item, .section {
        padding: 10px;
    }

    .button {
        width: 100%;
        text-align: center;
    }

    .footer {
        padding: 5px 0;
    }

    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .page-item {
        margin: 5px 0;
    }

    .home-header-section .text-content {
        padding: 20px;
    }

    .home-why-container div,
    .home-features-section .feature {
        width: 100%; /* Make features stack on mobile */
    }

    .section {
        width: 100%;
        padding: 10px;
        text-align: center;
        margin-bottom: 10px;
    }

    .features-section,
    .why-container,
    .screenshot-container {
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
    }

    .image-container div img {
        width: 100px; /* Adjust size as needed */
        height: 100px; /* Adjust size as needed */
    }

    .why-container div img,
    .screenshot-container div img {
        width: 100%; /* Make images responsive */
        height: auto;
    }

    .get-started-container {
        margin-top: 0;
        padding: 10px;
        width: 50%;
    }

    /* Add 5px space between sections */
    .container > .section,
    .admin-container > .section,
    .accessibility-container > .section,
    .category-posts-container > .section,
    .contact-us-container > .section,
    .dashboard-container > .section,
    .form-container > .section,
    .header-section,
    .choose-container {
        margin-bottom: 50px;
    }

    body {
        background-color: yellow;
      }
}
