@import url('mobile.css');


/* General styles */
body {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f7f9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
}

button {
    cursor: pointer;
    transition: background-color 0.3s;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

/* Container */
.container {
    max-width: 2500px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Section */
.section {
    position: relative;
    margin-bottom: 60px;
    text-align: center;
    left: -2%;
    padding: 10px;
    background: lightgrey;
    border-radius: 10px;
    width: 102.5%;
}

.section h1 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.section h2 {
    color: #47619a;
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.section p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.8;
}

/* Header Section */
.header-section {
    position: relative;
    left: -1.5%;
    top: -22px;
    width: 103%;
    height: 500px;
    background-image: url("/public/background.jpeg");
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.header-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.header-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.05);
    z-index: 2;
    animation: animateOverlay 10s linear infinite;
    background-size: 200% 200%;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.0) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.03) 50%, rgba(255, 255, 255, 0.03) 75%, transparent 75%, transparent);
}

@keyframes animateOverlay {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 100%;
    }
}

.header-section .text-content {
    position: relative;
    z-index: 3;
    max-width: 800px;
    text-align: center;
}

.header-section h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.header-section p {
    font-size: 1.2em;
    line-height: 1.6;
    padding: 0 20px;
    font-size: 20px;
}

.header-section a {
    display: inline-block;
    background-color: #47619a;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-section a:hover {
    background-color: #003f7f;
}

/* Features Section */
.features-section {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.features-section .feature {
    max-width: 250px;
}

.features-section .feature h3 {
    color: #47619a;
    margin-bottom: 10px;
}

.features-section .feature p {
    font-size: 16px;
}

/* Link Button */
.link-button {
    display: inline-block;
    background-color: #47619a;
    color: white;
    padding: 15px 30px;
    border-radius: 25px;
    font-size: 18px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.link-button:hover {
    background-color: #003f7f;
}

/* Image Container */
.image-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.image-container div {
    text-align: center;
    margin: 20px;
}

.image-container div img {
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 150px;
}

.image-container div p {
    font-size: 16px;
    color: #333;
}

/* Why Container */
.why-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.why-container div {
    flex: 0 1 45%;
    text-align: center;
    margin: 0px;
}

.why-container div img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 90%;
}

.why-container div p {
    font-size: 20px;
    color: #333;
}

.why-container div li {
    text-align: left;
    padding: 15px;
    list-style-type: none;
    font-size: 20px;
}

.why-container div b,
.why-container div strong {
    color: #47619a;
}

/* Choose Container */
.choose-container {
    position: relative;
    margin-top: -100px;
    width: 103%;
    left: -2%;
    flex-wrap: wrap;
    background-color: #47619a;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.choose-container h2 {
    padding: 15px;
    color: white;
}

.choose-container p {
    color: #b87333;
}

.choose-container .feature {
    max-width: 250px;
}

.choose-container .feature h3 {
    color: white;
    margin-bottom: 10px;
}

.choose-container .feature p {
    font-size: 16px;
    color: white;
}

/* Screen Shot Container */
.screenshot-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.screenshot-container div {
    flex: 0 1 45%;
    text-align: center;
    margin: 0px;
}

.screenshot-container div img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 90%;
}

.screenshot-container div p {
    font-size: 20px;
    color: #333;
}

.screenshot-container div li {
    text-align: left;
    padding: 15px;
    list-style-type: none;
    font-size: 20px;
}

.screenshot-container div b,
.screenshot-container div strong {
    color: #47619a;
}

/* Testimonial Container */
.testimonial-container {
    display: none;
}

/* Get Started Container */
.get-started-container {
    background-color: lightgrey;
    padding: 25px;
    position: relative;
    margin-top: -8%;
}

/* Footer */
.footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
}

.footer a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

/* Form Container */
.form-container {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

/* Input Field */
.input-field {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-field:focus {
    outline: none;
    border-color: #47619a;
}

/* Checkbox Container */
.checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.checkbox-container label {
    font-size: 14px;
    color: #666;
}

.checkbox-container input {
    margin-right: 5px;
}

/* Forgot Password Link */
.forgot-password-link {
    font-size: 14px;
    color: #47619a;
    text-decoration: none;
}

.forgot-password-link:hover {
    text-decoration: underline;
}

/* Select Field */
.select-field {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-field:focus {
    outline: none;
    border-color: #47619a;
}

/* White Div */
.white-div {
    width: 100%;
    height: 80px;
    background-color: white;
}

/* Shadow Div */
.shadow-div {
    width: 101.7%;
    height: 2px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    z-index: 100;
    margin-left: -19px;
    margin-top: -22px;
}


/* Container */
.admin-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

/* List Container */
.admin-list-container {
    margin-bottom: 40px;
}

.admin-list-container h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #47619a;
}

/* List Item */
.admin-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
    margin-bottom: 10px;
}

.admin-list-item span {
    font-size: 1em;
    color: #333;
}

/* Buttons */
.admin-button {
    background-color: #47619a;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
}

.admin-button:hover {
    background-color: #003f7f;
}

.admin-button:disabled {
    background-color: #b0b0b0;
}

/* Pagination */
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-container button {
    background-color: #47619a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s;
}

.pagination-container button:hover {
    background-color: #003f7f;
}

.pagination-container button:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}

/* Accessibility Statement Specific Styles */
.accessibility-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
}

.accessibility-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #47619a;
}

.accessibility-container h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.accessibility-container p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #666;
}

.accessibility-container ul {
    list-style-type: disc;
    padding-left: 20px;
}

.accessibility-container li {
    font-size: 1em;
    color: #666;
    margin-bottom: 5px;
}


/* Calendar Specific Styles */
.calendar-container {
    height: 500px;
    margin: 20px 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.rbc-calendar {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


/* CategoryPosts Container */
.category-posts-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Post Link */
.post-link {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.post-link:last-child {
    border-bottom: none;
}

.post-link:hover {
    background-color: #f1f1f1;
}

/* Post Info */
.post-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    color: #666;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.page-button {
    padding: 10px 15px;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
}

.page-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

/* Contact Us Container */
.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header */
.contact-us-header {
    color: #47619a;
    text-align: center;
    margin-bottom: 20px;
}

/* Form */
.contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Label */
.contact-us-label {
    font-size: 16px;
    color: #333;
}

/* Input */
.contact-us-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
}

/* TextArea */
.contact-us-textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    resize: vertical;
}

/* Button */
.contact-us-button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #47619a;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-us-button:hover {
    background-color: #003f7f;
}

/* Message */
.contact-us-message {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

.contact-us-message.error {
    color: #ff0000;
}

.contact-us-message.success {
    color: #008000;
}


/* Dashboard Container */
.dashboard-container {
    position: relative;
    margin-top: -1.1%;
    display: flex;
    border-top: 2px solid #202020;
}

/* Sidebar */
.sidebar {
    position: relative;
    width: 250px;
    background-color: #47619a;
    color: white;
    min-height: 100vh;
    padding: 20px;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
    z-index: 2;
    left: -2%;
    top: -2%;
}

/* Sidebar Item */
.sidebar-item {
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.sidebar-item:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
}

/* Main Content */
.main-content {
    flex: 1;
    padding: 40px;
    background-color: #f4f7f9;
    min-height: 100vh;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

/* Section */
.section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Title */
.section-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Card */
.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 20px;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Card Title */
.card-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Card Content */
.card-content {
    font-size: 16px;
    color: #666;
}

/* Button */
.button {
    background-color: #47619a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-decoration: none;
    display: inline-block;
}

.button:hover {
    background-color: #0056b3;
}

/* Notification Item */
.notification-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-left: 5px solid #47619a;
    border-radius: 5px;
}

.notification-item .notification-message {
    font-size: 16px;
    color: #333;
}

.notification-item .notification-date {
    font-size: 14px;
    color: #666;
}

.notification-item .dismiss-button {
    background: none;
    border: none;
    color: #47619a;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.notification-item .dismiss-button:hover {
    text-decoration: underline;
}

/* Pagination Container */
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Page Item */
.page-item {
    margin: 0 5px;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.page-item:hover {
    background-color: #47619a;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.page-item.active {
    font-weight: bold;
    background-color: #47619a;
    color: white;
}

/* Carousel Container */
.carousel-container {
    width: 100%;
}

.carousel-container .slick-slide {
    display: flex !important;
    /* Important to ensure flex is applied */
    justify-content: center;
    align-items: center;
    height: 100% !important;
    /* Ensure the carousel items take up full height */
}

/* Job Card */
.job-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
    margin-bottom: 20px;
    height: 250px;
    /* Ensures the card is square */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.job-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Client Info */
.client-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

/* Client Profile Pic */
.client-profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Client Profile Pic Wrapper */
.client-profile-pic-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* Client Name */
.client-name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

/* Job Title */
.job-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Job Description */
.job-description {
    font-size: 16px;
    color: #666;
}

/* Job Grid */
.job-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}


/* Add the necessary CSS from previous sections if not already included */
.dashboard-container {
    position: relative;
    margin-top: -1.1%;
    display: flex;
    border-top: 2px solid #202020;
}

.sidebar {
    position: relative;
    width: 250px;
    background-color: #47619a;
    color: white;
    min-height: 100vh;
    padding: 20px;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
    z-index: 2;
    left: -2%;
    top: -2%;
}

.sidebar-item {
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.sidebar-item:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
}

.main-content {
    flex: 1;
    padding: 40px;
    background-color: #f4f7f9;
    min-height: 100vh;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 20px;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.card-content {
    font-size: 16px;
    color: #666;
}

.button {
    background-color: #47619a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-decoration: none;
    display: inline-block;
}

.button:hover {
    background-color: #0056b3;
}

.notification-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-left: 5px solid #47619a;
    border-radius: 5px;
}

.notification-item .notification-message {
    font-size: 16px;
    color: #333;
}

.notification-item .notification-date {
    font-size: 14px;
    color: #666;
}

.notification-item .dismiss-button {
    background: none;
    border: none;
    color: #47619a;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.notification-item .dismiss-button:hover {
    text-decoration: underline;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-item {
    margin: 0 5px;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.page-item:hover {
    background-color: #47619a;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.page-item.active {
    font-weight: bold;
    background-color: #47619a;
    color: white;
}

.carousel-container {
    width: 100%;
}

.carousel-container .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100% !important;
}

.client-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.client-profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
}

.client-profile-pic-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.client-name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.job-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.job-description {
    font-size: 16px;
    color: #666;
}

.job-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}


/* Disclaimer page specific styles */
.disclaimer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.disclaimer-container h1 {
    color: #47619a;
    text-align: center;
    margin-bottom: 20px;
}

.disclaimer-container h2 {
    color: #333;
    margin-top: 20px;
}

.disclaimer-container p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

/* FAQ page specific styles */
.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-header {
    color: #47619a;
    text-align: center;
    margin-bottom: 20px;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-question {
    font-size: 18px;
    color: #333;
}

.faq-answer {
    font-size: 16px;
    color: #666;
}

/* ForumPage specific styles */
.forum-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.category-card {
    margin-bottom: 30px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.category-card:hover {
    transform: translateY(-5px);
}

.category-header {
    margin-bottom: 15px;
    color: #47619a;
}

.view-posts-link {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #47619a;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.view-posts-link:hover {
    background-color: #0056b3;
}



/* Home page specific styles */
.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.home-shadow-div {
    width: 101.7%;
    height: 2px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    z-index: 100;
    margin-left: -19px;
    margin-top: -22px;
    display: none;
}

.home-white-div {
    width: 100%;
    height: 80px;
    background-color: white;
}

.home-header-section {
    position: relative;
    left: -1.5%;
    top: -22px;
    width: 102.7%;
    height: 500px;
    background-image: url("/public/background.jpeg");
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.home-header-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.home-header-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.05);
    z-index: 2;
    animation: animateOverlay 10s linear infinite;
    background-size: 200% 200%;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.0) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.03) 50%, rgba(255, 255, 255, 0.03) 75%, transparent 75%, transparent);
}

@keyframes animateOverlay {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 100%;
    }
}

.home-header-section .text-content {
    position: relative;
    z-index: 3;
    max-width: 800px;
    text-align: center;
}

.home-header-section p {
    font-size: 1.2em;
    line-height: 1.6;
    padding: 0 20px;
    font-size: 20px;
}

.home-header-section a {
    display: inline-block;
    background-color: #47619a;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-header-section a:hover {
    background-color: #0056b3;
}

.home-why-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 20px;
}

.home-why-container div {
    flex: 0 1 45%;
    text-align: center;
    margin: 0px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    
}


.home-why-container div img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 90%;
}

.home-why-container div p {
    font-size: 20px;
    color: #333;
}

.home-why-container div li {
    text-align: left;
    padding: 15px;
    list-style-type: none;
    font-size: 20px;
}

.home-why-container div b,
.home-why-container div strong {
    color: #47619a;
}

.home-features-section {
    display: flex;
    justify-content: space-around;
    text-align: left;
    padding: 0 20px;
}

.home-features-section .feature {
    max-width: 250px;
}

.home-features-section .feature h3 {
    color: #47619a;
    margin-bottom: 10px;
}

.home-features-section .feature p {
    font-size: 16px;
}

.home-screenshot-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.home-screenshot-container div {
    flex: 0 1 45%;
    text-align: center;
    margin: 0px;
    margin-top: -5%;
}

.home-screenshot-container div img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 90%;
}

.home-testimonial-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    display: none;
}

.home-testimonial-container div {
    text-align: center;
    margin: 20px;
}

.home-testimonial-container div img {
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 150px;
}

.home-testimonial-container div p {
    font-size: 16px;
    color: #333;
}

.home-get-started-container {
    background-color: lightgrey;
    padding: 25px;
    position: relative;
    margin-top: -8%;
    width: 101%;
    left: -5%;
    margin-bottom: -10%;
}

.home-choose-container {
    position: relative;
    margin-top: -100px;
    width: 101%;
    left: -5%;
    flex-wrap: wrap;
    background-color: #47619a;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.home-choose-container h2 {
    padding: 15px;
    color: white;
}

.home-choose-container p {
    color: #b87333;
}

.home-choose-container .feature {
    max-width: 250px;
}

.home-choose-container .feature h3 {
    color: white;
    margin-bottom: 10px;
}

.home-choose-container .feature p {
    font-size: 16px;
    color: white;
}

.home-section {
    margin-bottom: 10%;
    width: 110%;
}


/* InfrastructureTestingStandards specific styles */
.infrastructure-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.infrastructure-header {
    color: #47619a;
    text-align: center;
    margin-bottom: 20px;
}

.infrastructure-section {
    margin-bottom: 20px;
}

.infrastructure-subheader {
    font-size: 18px;
    color: #202020;
    margin-bottom: 10px;
}

.infrastructure-list {
    font-size: 16px;
    color: #333;
    list-style-type: disc;
    margin-left: 20px;
}


/* Job page specific styles */
.job-page-container {
    position: relative;
    margin-top: -0.75%;
    display: flex;
    background-color: #f4f7f9;
    min-height: 100vh;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.job-page-container:hover {
    transform: translateY(-5px);
}

.job-page-container h1 {
    color: #47619a;
    margin-bottom: 20px;
    text-align: center;
}

.job-details,
.bid-details,
.file-details,
.message-details,
.notification-details {
    margin-bottom: 30px;
    background-color: #eaeaea;
    padding: 15px;
    border-radius: 10px;
}

.job-details h3,
.bid-details h2,
.file-details h2,
.message-details h2,
.notification-details h2 {
    color: #202020;
    margin-bottom: 15px;
}

.accepted-bid,
.bids,
.files,
.messages,
.notifications {
    margin-bottom: 10px;
}

.job-page-container button {
    background-color: #47619a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.job-page-container button:hover {
    background-color: #0056b3;
}

.job-page-sidebar {
    position: relative;
    width: 250px;
    background-color: #47619a;
    color: white;
    min-height: 100vh;
    padding: 20px;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
    z-index: 2;
    left: -2%;
    top: -2%;
    border-top: 1px solid #202020;
}

.job-page-sidebar-item {
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.job-page-sidebar-item:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
}

.job-page-main-content {
    flex: 1;
    padding: 40px;
    background-color: #f4f7f9;
    min-height: 100vh;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.job-page-file-input {
    display: block;
    margin-bottom: 10px;
}

.job-page-notification {
    background-color: #28a745;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.job-page-message-input {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 14px;
    resize: none;
}

.job-page-pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.job-page-pagination-container button {
    background-color: #47619a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s;
}

.job-page-pagination-container button:hover {
    background-color: #0056b3;
}

.job-page-pagination-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.job-page-tabs {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 20px;
    background-color: #eaeaea;
}

.job-page-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
}

.job-page-dropdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
}

.job-page-file-upload {
    display: block;
    margin-bottom: 10px;
}

.job-page-finding-input .ql-editor {
    min-height: 150px;
}

.job-page-tab {
    flex: 1;
    padding: 15px 20px;
    background-color: #fff;
    color: #47619a;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.job-page-tab:hover {
    background-color: #eaeaea;
    color: #47619a;
}

.job-page-tab-active {
    background-color: #47619a;
    color: white;
}

.job-page-content-container {}

.job-page-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 20px;
    flex: 1;
}

.job-page-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.job-page-card-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.job-page-card-container li {
    list-style-type: none;
}

.job-page-checkboxes {}

.job-page-checkbox-label {
    display: flex;
    align-items: center;
}

.job-page-checkbox-label input {
    margin-right: 10px;
}

.job-page-horizontal-card-container {
    display: flex;
    gap: 20px;
}


/* Login page specific styles */

.login-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    font-size: 14px;
}

.login-checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.login-forgot-password-link {
    color: var(--primary);
    text-decoration: none;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.login-button:hover {
    background-color: var(--primary-dark);
}

.login-link-button {
    display: block;
    margin-top: 10px;
    padding: 10px;
    background-color: var(--primary);
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.login-link-button:hover {
    background-color: var(--primary-dark);
}

/* notification page specific styles */

.notifications-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notifications-container h1 {
    color: var(--primary);
    margin-bottom: 20px;
}

.notification-list {
    max-height: 400px;
    overflow-y: auto;
}

.notification-item {
    background-color: var(--light);
    padding: 10px;
    margin-bottom: 10px;
    border-left: 4px solid var(--primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification-item h4 {
    margin: 0 0 5px 0;
}

.notification-item p {
    margin: 0;
}

.notification-item button {
    background: none;
    border: none;
    color: var(--primary);
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 5px;
}

.pagination button:disabled {
    background-color: var(--disabled);
    cursor: not-allowed;
}

/* PostJob page specific styles */

.post-job-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-job-container h1 {
    color: var(--primary);
    margin-bottom: 20px;
}

.post-job-container form {
    display: flex;
    flex-direction: column;
}

.post-job-container form label {
    margin-bottom: 10px;
    font-weight: bold;
}

.post-job-container form input,
.post-job-container form textarea {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.post-job-container form button {
    background-color: var(--primary);
    color: var(--white);
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
}

.post-job-container form button:hover {
    background-color: var(--dark);
}

/* PostPge page specific styles */

.post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: var(--white);
}

.post-header {
    margin-bottom: 20px;
}

.post-content {
    font-size: 16px;
    color: var(--text);
}

.comments-container {
    padding: 10px 0;
}

.comment {
    border-top: 1px solid #ddd;
    padding: 10px 0;
}

.comment:first-child {
    border-top: none;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.page-button {
    padding: 10px 15px;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: var(--white);
    cursor: pointer;
}

.page-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

/* PrivacyPolicy page specific styles */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 20px;
}

.privacy-policy-container h2 {
    font-size: 18px;
    color: var(--secondary);
    margin-bottom: 10px;
}

.privacy-policy-container p {
    font-size: 16px;
    color: var(--text);
    margin-bottom: 20px;
}

.privacy-policy-container ul {
    font-size: 16px;
    color: var(--text);
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

/* Profile page specific styles */

.profile-container {
    padding: 20px;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-header img {
    border-radius: 50%;
    margin-right: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.profile-header h1 {
    color: var(--primary);
    margin: 0;
}

.profile-details {
    margin-bottom: 20px;
}

.profile-details p {
    margin: 5px 0;
    font-size: 16px;
    color: var(--text);
}

.profile-details button {
    background-color: var(--primary);
    color: var(--white);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-details button:hover {
    background-color: var(--primary-dark);
}

.edit-form {
    display: flex;
    flex-direction: column;
}

.edit-form label {
    margin-bottom: 10px;
    color: var(--text);
}

.edit-form input,
.edit-form textarea {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid var(--border);
    font-size: 16px;
}

.edit-form button {
    background-color: var(--primary);
    color: var(--white);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-form button:hover {
    background-color: var(--primary-dark);

    /* SearchResults page specific styles */

    .search-results-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
    }

    .post-link {
        display: block;
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    .post-link:last-child {
        border-bottom: none;
    }

    .post-link:hover {
        background-color: #f1f1f1;
    }
}


/* SearchResults page specific styles */

.terms-of-service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms-of-service-container h1 {
    color: #47619a;
    text-align: center;
    margin-bottom: 20px;
}

.terms-of-service-container h2 {
    font-size: 18px;
    color: #47619a;
    margin-bottom: 10px;
}

.terms-of-service-container p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.terms-of-service-container ul {
    list-style-type: disc;
    margin-left: 20px;
}

.terms-of-service-container ul li {
    margin-bottom: 10px;
}

/* WebTestingStandards page specific styles */

.standards-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.standards-container h1 {
    color: #47619a;
    text-align: center;
    margin-bottom: 20px;
}

.standards-container h2 {
    color: #202020;
    margin-top: 20px;
}

.standards-container p {
    margin-bottom: 10px;
    line-height: 1.6;
}

.standards-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.standards-container a {
    color: #47619a;
    text-decoration: none;
}

.standards-container a:hover {
    text-decoration: underline;
}

/* AdminModal page specific styles */


.admin-modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.admin-modal-overlay.show {
    display: block;
}

.admin-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 1001;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
}

.admin-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.admin-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.admin-modal-button {
    margin-left: 10px;
}


/* BidForm page specific styles */

.bid-form-container {
    margin-top: 20px;
}

.bid-form-container form {
    display: flex;
    flex-direction: column;
}

.bid-form-container label {
    margin-bottom: 10px;
    font-weight: bold;
}

.bid-form-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.bid-form-button {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
}

.bid-form-button:hover {
    background-color: var(--dark-color);
}

/* CreateCommentForm page specific styles */

.create-comment-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.create-comment-form label {
    font-weight: bold;
    color: var(--text-color);
}

.create-comment-textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    font-size: 16px;
    width: 100%;
}

/* CreatePostForm page specific styles */

.create-post-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.create-post-form label {
    font-weight: bold;
    color: var(--text-color);
}

.create-post-input,
.create-post-select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    font-size: 16px;
    width: 100%;
}

.create-post-editor .ql-container {
    height: 450px;
    /* Adjust the height of the editor */
}

/* Header page specific styles */

.header-container {
    background-color: #47619a;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
    padding: 5px 0;
    border-bottom: solid #000000;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #47619a;
}

.nav-bar a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.nav-bar a:hover {
    text-decoration: underline;
}

.nav-bar a svg {
    margin-right: 5px;
}

.logo {
    height: 80px;
    margin-right: 10px;
    margin-left: 35%;
    padding: 5px 10px;
}

.logo[data-has-notifications="true"] {
    filter: hue-rotate(180deg);
}

.user-name {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.user-name:hover .profile-dropdown-content {
    display: block;
}

.profile-picture {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: white;
    padding: 0 10px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    color: #47619a;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    border-radius: 5px;
    top: 100%;
    left: 0;
}

.dropdown-content a {
    color: #47619a !important;
}

.dropdown-content a,
.dropdown-content span {
    color: #47619a;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover,
.dropdown-content span:hover {
    background-color: #f1f1f1;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu:hover .dropdown-submenu-content {
    display: block;
}

.dropdown-submenu-content {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 160px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    color: #47619a !important;
}

.dropdown-submenu-content a {
    padding: 12px 16px;
    color: #47619a !important;
    text-decoration: none;
    display: block;
}

.dropdown-submenu-content a:hover {
    background-color: #f1f1f1;
}

.profile-dropdown-content {
    left: auto;
    right: 0;
    min-width: 200px;
    top: 50px;
}

.logout-button {
    padding: 5px 10px;
    margin-left: 10px;
    background-color: #eaa15a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.logout-button svg {
    margin-right: 5px;
}

.logout-button:hover {
    background-color: #003f7f;
}



/* Modal page specific styles */

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 800px;
    height: 800px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: var(--color-primary);
}

.close-button:hover {
    color: var(--color-primary-dark);
}


/* NotificationIcon page specific styles */

.icon-container {
    position: relative;
    display: inline-block;
}

.red-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}


/* ReviewList page specific styles */

.review-container {
    padding: 20px;
    background-color: var(--color-white);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.review-item {
    padding: 20px;
    background-color: var(--color-light);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.reviewer-name {
    font-weight: bold;
    margin: 0 0 5px 0;
}

.rating {
    margin: 0 0 5px 0;
}

.comment {
    margin: 0;
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 5px;
}

.pagination button:disabled {
    background-color: var(--color-disabled);
    cursor: not-allowed;
}

/* SearchBar page specific styles */

.search-bar-container {
    display: flex;
    margin-bottom: 20px;
}

.search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px 0 0 5px;
}

.search-button {
    padding: 10px 20px;
    border: 1px solid #47619a;
    border-left: none;
    background-color: #47619a;
    color: white;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    height: 10%;
    margin-top: 0.8%;
}

.search-button:hover {
    background-color: #eaa15a;
}

/* Sidebar page specific styles */

.sidebar-container {
    width: 250px;
    background-color: var(--primary-color);
    color: white;
    height: 100vh;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-link {
    display: block;
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
    font-size: 18px;
    transition: background-color 0.3s, padding-left 0.3s;
}

.sidebar-link:hover {
    background-color: var(--primary-dark-color);
    padding-left: 10px;
}


/* StarRating page specific styles */

.star-container {
    display: inline-block;
}

.star {
    color: var(--primary-color);
    font-size: 20px;
}





.dashboard-container {
    position: relative;
    margin-top: -1.1%;
    display: flex;
    border-top: 2px;
}

.sidebar {
    position: relative;
    width: 250px;
    background-color: #47619a;
    color: white;
    min-height: 100vh;
    padding: 20px;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
    z-index: 2;
    left: -2%;
    top: -2%;
    border-top: 1px solid #202020;
}

.sidebar-item {
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.sidebar-item:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
}

.main-content {
    flex: 1;
    padding: 40px;
    background-color: #f4f7f9;
    min-height: 100vh;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 20px;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.card-content {
    font-size: 16px;
    color: #666;
}

.button {
    background-color: #47619a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-decoration: none;
    display: inline-block;
}

.button:hover {
    background-color: #0056b3;
}

.notification-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-left: 5px solid #47619a;
    border-radius: 5px;
}

.notification-message {
    font-size: 16px;
    color: #333;
}

.notification-date {
    font-size: 14px;
    color: #666;
}

.dismiss-button {
    background: none;
    border: none;
    color: #47619a;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.dismiss-button:hover {
    text-decoration: underline;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-item {
    margin: 0 5px;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.page-item:hover {
    background-color: #47619a;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.page-item.active {
    font-weight: bold;
    background-color: #47619a;
    color: white;
}

.carousel-container {
    width: 100%;
}

.carousel-container .slick-slide {
    display: flex !important;
    /* Important to ensure flex is applied */
    justify-content: center;
    align-items: center;
    height: 100% !important;
    /* Ensure the carousel items take up full height */
}



.client-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.client-profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
}

.client-profile-pic-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.client-name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.job-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.job-description {
    font-size: 16px;
    color: #666;
}

.job-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}



.menu-icon {
    display: none;
    font-size: 30px;
    cursor: pointer;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.nav-bar a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
}

.nav-bar a:hover {
    text-decoration: underline;
}

/* Mobile styles */
@media (max-width: 912px) {
    .menu-icon {
        display: block;
        margin-right: 10%;
    }

    .nav-bar {
        display: none;
        flex-direction: column;
        width: 100%;
        background-color: #47619a;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 1;
    }

    .nav-bar.open {
        display: flex;
    }

    .nav-bar a {
        margin: 10px 0;
    }

    .container,
    .admin-container,
    .accessibility-container,
    .category-posts-container,
    .contact-us-container,
    .dashboard-container,
    .form-container {
        padding: 10px;
    }

    .header-section,
    .choose-container,
    .section {
        left: 0;
        width: 100%;
        margin-bottom: 20px;

    }

    .header-section .text-content h1 {
        font-size: 2em;
    }

    .header-section p,
    .section p {
        font-size: 1em;
    }

    .sidebar {
        width: 100%;
        position: relative;
        box-shadow: none;
    }

    .main-content {
        padding: 20px;
    }

    .features-section,
    .why-container {
        flex-direction: column;
        align-items: center;
    }

    .job-grid {
        grid-template-columns: 1fr;
    }

    .card,
    .job-card,
    .notification-item,
    .section {
        padding: 10px;
    }

    .button {
        width: 100%;
        text-align: center;
    }

    .footer {
        padding: 5px 0;
    }

    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .page-item {
        margin: 5px 0;
    }

    .home-header-section .text-content {
        padding: 20px;
        height: 50%;
    }

    .home-why-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-left:-4.5%;
    }

    .home-why-container div {
        width: 100%;
        height: 50%;
        padding: 5px;
        flex-direction: column;
        align-items: center;
        margin-top: -30%;
    }

    .home-why-container li {
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        margin-left: 5%;
    }

    .home-why-container div img {
        flex-direction: column;
        align-items: center;
        margin-top: 5%;
        height: auto !important;
        width: auto !important;
        max-width: 100%; 
        max-height: 100%;
        margin-left: 2.5%;
        margin-top: 30%;
    }

    
    
    .home-why-container .link-button {
        margin-left: 1% !important;
    }

    .home-features-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: -8%;
    }

    .home-features-section .feature {
        padding: 2%;
        margin: 2%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        place-self: center;
        width: 80%;
    }

    .home-features-section .feature p, h3{
        align-content: right !important;
    }

    .section {
        width: 100%;
        padding: 10px;
        text-align: center;
        margin-bottom: 10px;
    }

    .features-section,
    .why-container,
    .screenshot-container {
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
    }

    .image-container div img {
        width: 100px;
        height: 100px;
    }

    .why-container div img,
    .screenshot-container div img {
        width: 100%;
        height: auto;
    }


    .get-started-container {
        margin-top: 0;
        padding: 10px;
        width: 50%;

    }

    /* Add 5px space between sections */
    .container>.section,
    .admin-container>.section,
    .accessibility-container>.section,
    .category-posts-container>.section,
    .contact-us-container>.section,
    .dashboard-container>.section,
    .form-container>.section,
    .header-section,
    .choose-container {
        margin-bottom: 5px;
    }

    .home-section {
        margin-bottom: 110px;
    }

    .home-choose-container {
        width: 101%;
        margin-left: -3%;
        left: -2.5%;
    }

    .home-get-started-container {
        width: 100.5%;
        margin-left: -3%;
        left: -2%;
        margin-top: -10%;
    }

    .home-header-section {
        width: 104.5%;
    }

    .home-white-div {
        height: 100px;
    }

    .home-header-section {
        left: -3%;
        width: 105.5%;
        height: 50%;
        padding-top: 5%;
    }

    .home-header-section .text-content {
        left: 0%;
    }

    .home-screenshot-container {
        margin-top: -8%;
        flex-direction: column;
        align-items: center;
        margin-left: -6%;
    } 

    .home-screenshot-container p {
        padding: 4%;
        align-self: center;
        
    }

    .home-screenshot-container h2 {
        padding: 2%;
        align-self: center;
        padding: 2%;
    }

    .home-screenshot-container img {
        height: auto !important;
        width: auto !important;
        max-width: 85%; 
        max-height: 85%;
        margin-top: 5%;
    }

    .header-container .logo {
        left: 5px !important;
    }

    :root .header-container {
        min-width: 375px;
        width: auto;
    }

    :root .footer {
        min-width: 375px;
        width: auto;
    }

    .logo {
        margin-left: 10%;
        width: 75%;
        height: auto;
    }

    .divider {
       display: none; 
    }

    :root {
        width: auto;
        min-width: 800px;
    }
}